import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
// import "./custom.css"
import AppleLogin from "./components/AppleLogin";
import NavText from "./components/NavText";
import GLogin from "./components/GLogin";
import "./SignUp&Login.css";

export default function FirstView({ viewModel }) {
  const [shouldUpdate, setShouldUpdate] = useState(true);

  window.delegate = {
    pageDidAppear: () => {
      viewModel.refreshView();
    },
    pageShouldRefresh: () => {
      setShouldUpdate(!shouldUpdate);
      console.log("pageShouldRefresh");
    },
  };

  useEffect(() => {
    viewModel.refreshView();
  }, []);

  return (
    <div>
      <div
        style={{
          color: "var(--c-accent)",
          textAlign: "right",
          margin: 20,
          fontWeight: "medium",
        }}
        onClick={() => {
          window.planetx.view.dismiss();
        }}
      >
        {viewModel.allowSkip ? "Skip" : <div style={{ height: 24 }} />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            marginTop: 50,
            marginBottom: 250,
            fontSize: "xxx-large",
            fontWeight: "800",
            color: "var(--c-primary-label)",
          }}
        >
          Planet XR
        </h1>

        <div>
          <button
            type="button"
            className="primaryButton inputSizePrimary"
            onClick={() => {
              window.planetx.view.navigate({
                path: "app/email",
                query: `skip=${viewModel.allowSkip ? "allow" : "disallow"}`,
                fullScreen: false,
              });
            }}
          >
            Continue with email
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 55,
          }}
        >
          <small
            style={{
              color: "var(--c-sub-label)",
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            Or continue with:
          </small>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 20,
            }}
          >
            <GLogin />
            <AppleLogin />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <small style={{ color: "var(--c-sub-label)" }}>
            Already have an account?
          </small>
          <NavText
            displayText={"LOG IN"}
            page={{
              path: "app/login",
              query: `skip=${viewModel.allowSkip ? "allow" : "disallow"}`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
