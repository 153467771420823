import React, { useState } from "react";
import NetworkService from "../../../Services/NetworkService";
import UserListItem from "./UserListItem";
import { useCookies } from "react-cookie";

function UsersMainView() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  if (isLoading) {
    Promise.all([
      NetworkService.usersQueries(cookies.token).getAllUsers(),
    ]).then((result) => {
      setUsers(result[0].data.users);
      setIsLoading(false);
    });
  }

  const usersList = users.map((user) => (
    <li key={user.id}>
      <UserListItem user={user} />
    </li>
  ));

  return (
    <div>
      <ul>{usersList}</ul>
    </div>
  );
}

export default UsersMainView;
