import { FcGoogle } from "react-icons/fc";
import { Button } from "react-bootstrap";
import NetworkService from "../../Services/NetworkService";
import "../SignUp&Login.css";
import "../../index.css";

export default function GLogin() {
  const didLoginGoogle = async (idToken) => {
    try {
      const res = await NetworkService.userMutations("").loginWithGoogle(
        idToken
      );
      window.planetx.auth.login({
        token: res.data.loginWithGoogle.token,
        provider: "Google",
      });
      window.planetx.view.dismiss();
    } catch (error) {
      window.planetx.error(error.message);
    }
  };
  return (
    <Button
      variant=""
      className="externalLogin px-card"
      onClick={() => {
        window.planetx.auth.requestGoogleLogin().then((idToken) => {
          // setGoogleToken(idToken);
          didLoginGoogle(idToken);
        });
      }}
    >
      <FcGoogle size="30px" />
    </Button>
  );
}
