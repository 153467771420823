import Routers from "./Views/Routes";
import LoginPage from "./Views/LoginPage";
import { useCookies } from "react-cookie";
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <CookiesProvider>
      <Routers />
    </CookiesProvider>
  );
}

export default App;
