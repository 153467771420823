import React, { useState } from "react";
import NetworkService from "../Services/NetworkService";
import { GoogleLogin } from "react-google-login";
import AppleLogin from "react-apple-login";
import { useCookies } from "react-cookie";
import {
  Form,
  Col,
  Card,
  Container,
  Row,
  Navbar,
  Button,
  Alert,
} from "react-bootstrap";
function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  console.log(cookies);
  const login = (e) => {
    setError("");
    NetworkService.usersQueries("")
      .login(email, password)
      .then((response) => {
        setCookie("token", response.data.login.token, { path: "/admin" });
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  const didLoginGoogle = (response) => {
    console.log(response);
    NetworkService.userMutations("")
      .loginWithGoogle(response.tokenId, "Web")
      .then((response) => {
        setCookie("token", response.data.loginWithGoogle.token, {
          path: "/admin",
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">PlanetX Web</Navbar.Brand>
      </Navbar>
      <Container>
        <Row></Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card style={{ padding: "16px", margin: "16px" }}>
              <Card.Title>Login</Card.Title>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" onClick={login}>
                  Login
                </Button>
                <br />
                <br />
                <GoogleLogin
                  clientId="1092665514133-hen0o55b31f752j570p6d3gjt63k1ro6.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={didLoginGoogle}
                  onFailure={didLoginGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </Form>
              <br />

              {error ? <Alert variant="danger">{error}</Alert> : <div />}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginPage;
