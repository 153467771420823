import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ModelsLibraryDetailView from "./ModelsLibraryDetailView";
import { gql, useQuery } from "@apollo/client";

const useStyles = makeStyles({
  root: {
    height: 150,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const GET_ALL_MODELS = gql`
  query GetAllModels {
    allModels {
      name
      id
      thumbnailURL
      models {
        id
        author
        name
        modelDescription
        usdzFileURL
        modelFileURL
        uploader {
          email
          lastName
          firstName
        }
        updated
        thumbnailURL
        avaliable
        category {
          name
        }
      }
    }
  }
`;

function ModelsLibrary() {
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(GET_ALL_MODELS);
  const [focused, setFocused] = useState(null);
  const [showNewModel, setNewModel] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const newModel = (e) => {
    setNewModel(true);
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    );
  }

  if (error) {
    if (error.networkError.statusCode == 400) {
      removeCookie("token", { path: "/admin" });
    }
    return <p>{JSON.stringify(error)}</p>;
  }

  const selectCategory = (category) => {
    let item = {
      id: category.id,
      name: category.name,
      models: category.models,
      thumbnailURL: category.thumbnailURL,
      type: "category",
    };
    setFocused(item);
  };

  const selectModel = (model) => {
    let item = {
      name: model.name,
      id: model.id,
      author: model.author,
      avaliable: model.avaliable,
      uploader: model.uploader,
      modelFileURL: model.modelFileURL,
      usdzFileURL: model.usdzFileURL,
      thumbnailURL: model.thumbnailURL,
      type: "model",
      category: model.category,
      modelDescription: model.modelDescription,
    };
    setFocused(item);
  };

  const renderRoot = (categories) => {
    return (
      <TreeItem key="root" nodeId="root" label={"All Models"}>
        {categories.map((category) => renderCategory(category))}
      </TreeItem>
    );
  };

  const renderCategory = (category) => {
    return (
      <TreeItem
        key={category.id}
        nodeId={category.id}
        onLabelClick={(e) => {
          e.preventDefault();
          selectCategory(category);
        }}
        label={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: "64px", width: "64px", padding: "8px" }}
              src={category.thumbnailURL}
            />
            <div>
              <Typography variant="body2" className={classes.labelText}>
                {category.name}
              </Typography>
              <Typography variant="caption" color="inherit">
                {`${(category.models || []).length || 0} Models`}
              </Typography>
            </div>
          </div>
        }
      >
        {Array.isArray(category.models)
          ? category.models.map((model) => renderModel(model))
          : null}
      </TreeItem>
    );
  };

  const renderModel = (model) => {
    return (
      <TreeItem
        key={model.id}
        nodeId={model.id}
        onClick={(e) => {
          selectModel(model);
        }}
        label={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: "30px", width: "30px", margin: "8px" }}
              src={model.thumbnailURL}
            />
            <div>
              <Typography variant="body2" className={classes.labelText}>
                {model.name}
              </Typography>
            </div>
          </div>
        }
      />
    );
  };

  return (
    <Container>
      <Modal
        show={showNewModel}
        onHide={(e) => {
          setNewModel(false);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Model</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ModelsLibraryDetailView
            focused={{ type: "model", isNew: true }}
            dismiss={(created) => {
              refetch();
              console.log(created);
              setFocused(created);
              setNewModel(false);
            }}
          />
        </Modal.Body>
      </Modal>
      <Row></Row>
      <Row>
        <Col sm={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <h4>Models Library</h4>
            <div style={{ flex: 1 }} />
            <Button
              style={{
                marginLeft: "4px",
                marginRight: "4px",
              }}
              variant="primary"
              onClick={newModel}
            >
              +
            </Button>
          </div>

          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={["root"]}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderRoot(data.allModels)}
          </TreeView>
        </Col>
        <Col sm={9}>
          <ModelsLibraryDetailView
            focused={focused}
            refresh={() => {
              refetch();
              setFocused(null);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ModelsLibrary;
