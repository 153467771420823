import React, { useState, useEffect } from "react";
import NetworkService from "../Services/NetworkService";
import { Form, Alert, Card } from "react-bootstrap";
import GLogin from "./components/GLogin";
import AppleLogin from "./components/AppleLogin";
import NavText from "./components/NavText";
import "./SignUp&Login.css";

export default function Login({ viewModel }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  window.delegate = {
    pageDidAppear: () => {
      viewModel.refreshView();
    },
    pageShouldRefresh: () => {
      setShouldUpdate(!shouldUpdate);
    },
  };

  useEffect(() => {
    viewModel.refreshView();
  }, []);

  useEffect(() => {
    setError("");
  }, [email, password]);

  return (
    <div>
      <div className="flexCloumn">
      <h1
          style={{
            marginTop: 50,
            marginBottom: 150,
            fontSize: "x-large",
            fontWeight: "800",
            color: "var(--c-primary-label)",
          }}>
          Log In
        </h1>

        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Username or email"
              value={email}
              className={
                error
                  ? "inputFormError inputSizePrimary"
                  : "inputForm inputSizePrimary"
              }
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              className={
                error
                  ? "inputFormError inputSizePrimary"
                  : "inputForm inputSizePrimary"
              }
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <FcGoogle /> */}
            {/* </Form.Control> */}
          </Form.Group>
          {/* <NavText
            displayText={"Forgot your password?"}
            navAddress={"app/login"}
          /> */}

          {error ? (
            <Alert className="errorAltert">{error}</Alert>
          ) : (
            <div style={{ height: 65 }}>
              <br />
            </div>
          )}
          <div>
            <button
              type="button"
              className="primaryButton inputSizePrimary"
              onClick={() =>
                viewModel.login({ email, password, setError }).then()
              }
            >
              Login
            </button>
            <br />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 55,
            }}
          >
            <small
              style={{
                color: "var(--c-sub-label)",
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              Or continue with:
            </small>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 20,
              }}
            >
              <GLogin />
              <AppleLogin />
            </div>
          </div>
          <div>
            <small style={{ color: "var(--c-sub-label)" }}>
              Don't have an account?
            </small>
            <NavText
              displayText={"SIGN UP"}
              page={{
                path: "app/email",
                query: `skip=${viewModel.allowSkip ? "allow" : "disallow"}`,
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
}
