import NetworkService from "../Services/NetworkService";

const emailPattern = "^[a-zA-Z0-9]+_?.?[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$";
const regexEmail = new RegExp(emailPattern);

const userIdPattern = "^[0-9A-Za-z_.]+$";
const regexUserId = new RegExp(userIdPattern);

var SignUpAndLoginViewModel = function ({ allowSkip, refreshView, page }) {
  return {
    allowSkip,
    refreshView: function () {
      refreshView(page, allowSkip);
    },
    emailCorrectToProceed: async function ({
      email,
      password,
      setError,
      setShowLogin,
    }) {
      if (email == "" || password == "") {
        setError("Please enter your email or password");
        return;
      }
      if (!regexEmail.test(email)) {
        setError("Invalid email format");
        return;
      }
      //Check email exist
      const res = await NetworkService.usersQueries("").emailExist(email);
      if (res.data.emailExist) {
        setError("Email already exists!");
        setShowLogin(true);
        return;
      }
      //proceed to next page
      window.planetx.view.navigate({
        path: "app/userid",
        query: `skip=${allowSkip ? "allow" : "disallow"}`,
        fullScreen: false,
        props: {
          email: email,
          password: password,
        },
      });
    },
    login: async function ({ email, password, setError }) {
      setError("");
      try {
        const res = await NetworkService.usersQueries("").login(
          email,
          password
        );
        window.planetx.auth.login({
          token: res.data.login.token,
          provider: "Password",
        });
        //close the page
        window.planetx.view.dismiss();
      } catch (error) {
        setError(error.message);
      }
    },
    checkNickNameUnique: async function ({ userId, setError }) {
      if (userId !== "" && !regexUserId.test(userId)) {
        setError(
          "User Id can only user Roman letters(a-z, A-Z), numbers, underscores and periods"
        );
        return;
      }
      const res = await NetworkService.usersQueries("").checkNickNameUnique(
        userId
      );
      if (res.data.checkNickNameUnique) {
        setError(""); //nickname doesn't exist in planetx db
        //todo: become green with a check saying this userId is avaliable
        return;
      }
      setError(`The user ID ${userId} is not avaliable`);
    },
    createUser: async function ({ userId, email, password, setError, error }) {
      if (error !== "") return;
      if (userId == "") {
        setError("Please enter the user ID you wish to use");
        return;
      }
      try {
        const res = await NetworkService.userMutations("").createUser(
          email,
          password,
          userId
        );
        window.planetx.auth.login({
          token: res.data.createUser.token,
          provider: "Password",
        });
        window.planetx.view.dismiss(); //close page
      } catch (error) {
        window.planetx.error(error.message);
        setError(error.message);
      }
    },
  };
};

export default SignUpAndLoginViewModel;
