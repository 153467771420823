import React, { useState, useEffect } from "react";
import { Form, Card, Alert } from "react-bootstrap";
import "./SignUp&Login.css";

export default function UserId({ viewModel }) {
  const email = window.props.email;
  const password = window.props.password;

  const [userId, setUserId] = useState("");

  const [error, setError] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(true);

  window.delegate = {
    pageWillAppear: () => {
      viewModel.refreshView();
    },
    pageShouldRefresh: () => {
      setShouldUpdate(!shouldUpdate);
      console.log("pageShouldRefresh");
    },
  };

  useEffect(() => {
    viewModel.refreshView();
  }, []);

  useEffect(() => {
    return viewModel.checkNickNameUnique({ userId, setError });
  }, [userId]);

  return (
    <div>
      <div
        style={{
          //primary container?
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card.Title
          style={{ marginTop: 100, marginBottom: 83, color: "white" }}
        >
          Add a user ID
        </Card.Title>

        <span className="primarySubTitle">
          Friends will add you using this ID
        </span>

        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Group controlId="formBasicText">
            <Form.Control
              type="text"
              name="userId"
              placeholder="User ID"
              value={userId}
              className={
                error
                  ? "inputFormError inputSizePrimary"
                  : "inputForm inputSizePrimary"
              }
              onChange={(e) => setUserId(e.target.value.replace(" ", "_"))}
            />
          </Form.Group>

          {error ? (
            <div style={{ marginBottom: 40 }}>
              <Alert variant="" className="errorAltert">
                {error}
              </Alert>
            </div>
          ) : (
            <div style={{ height: 130 }}>
              <br />
            </div>
          )}
          <div>
            <button
              type="button"
              className="primaryButton inputSizePrimary"
              onClick={() =>
                viewModel.createUser({
                  userId,
                  email,
                  password,
                  setError,
                  error,
                })
              }
            >
              Confirm
            </button>
            <br />
          </div>
        </Form>
      </div>
    </div>
  );
}
