import { gql, useMutation } from "@apollo/client";
import { Spinner, Button } from "react-bootstrap";

const SAVE_MODEL = gql`
  mutation UpdateModel($model: InputModel!) {
    updateModel(model: $model) {
      id
    }
  }
`;

function SaveModelView(props) {
  const [mutateFunction, { data, loading, error }] = useMutation(SAVE_MODEL, {
    refetchQueries: ["GetAllModels"],
  });
  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    );
  } else {
    return (
      <Button
        style={{
          marginLeft: "4px",
          marginRight: "4px",
        }}
        variant="primary"
        onClick={(e) => {
          console.log(props.model);
          mutateFunction({ variables: { model: props.model } });
        }}
      >
        Save
      </Button>
    );
  }
}
export default SaveModelView;
