import NetworkService from "../../Services/NetworkService";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Form, Col, Image, Button, Spinner } from "react-bootstrap";

function CurrenrUserView() {
  const [currentUser, setCurrentUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["token", "email"]);
  if (!currentUser) {
    console.log(cookies.token);
    if (cookies.token) {
      NetworkService.usersQueries(cookies.token)
        .currentUser()
        .then((result) => {
          setCookie("email", result.data.currentUser.email, { path: "/admin" });
          setCurrentUser(result.data.currentUser);
        })
        .catch((error) => {
          removeCookie("token", { path: "/dmin" });
        });
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }

    return <Redirect to="/" />;
  } else {
    if (!cookies["token"]) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Form.Row className="align-items-center">
          <Col xs="auto" className="my-1">
            <Image
              style={{ height: "30px", width: "30px" }}
              src={currentUser.profilePictureURL}
              roundedCircle
            />
          </Col>
          <Col xs="auto" className="my-1">
            <Form.Label>
              {currentUser.email + "(" + currentUser.nickName + ")"}
            </Form.Label>
          </Col>
          <Col xs="auto" className="my-1">
            <Button
              variant="secondary"
              onClick={(e) => {
                removeCookie("token", { path: "/admin" });
                removeCookie("email", { path: "/admin" });
              }}
            >
              Logout
            </Button>
          </Col>
        </Form.Row>
      </div>
    );
  }
}

export default CurrenrUserView;
