import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

var SessionQueries = (client) => {
  return {
    getAllSession: () => {
      return client.query({
        query: gql`
          query GetAllSession {
            resolveByFilter(inputQueryFilter: {}) {
              id
              posts {
                content
                image {
                  downloadUrl
                }
              }
              device {
                deviceName
                user {
                  nickName
                }
                phoneVersion
              }
              startTime
              location {
                latitude
                longitude
                altitude
              }
            }
          }
        `,
      });
    },
  };
};

export default SessionQueries;
