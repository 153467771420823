import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

var ModelsMutations = (client) => {
  return {
    createModel: (model) => {
      return client.mutate({
        mutation: gql`
          mutation CretaeModel($model: InputModel!) {
            createModel(model: $model) {
              id
            }
          }
        `,
        variables: { model },
      });
    },
    updateModel: (model) => {
      return client.mutate({
        mutation: gql`
          mutation UpdateModel($model: InputModel!) {
            updateModel(model: $model) {
              id
            }
          }
        `,
        variables: { model },
      });
    },
    updateCategory: (id, name, thumbnailURL) => {
      return client.mutate({
        mutation: gql`
              mutation UpdateCategory {
                updateCategory(id: ${id}, name: ${name}, thumbnailURL: ${thumbnailURL}) {
                    id
                }
            }
            `,
      });
    },
    removeModel: (id) => {
      console.log("removeModel");
      console.log(client);
      return client.mutate({
        mutation: gql`
              mutation RemoveModel {
                removeModel(id: "${id}")
            }
            `,
      });
    },
    removeCategory: (id) => {
      return client.mutate({
        mutation: gql`
              mutation RemoveCategory {
                removeCategory(id: "${id}")
            }
            `,
      });
    },
  };
};

export default ModelsMutations;
