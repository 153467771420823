import { gql, useMutation } from "@apollo/client";
import { Spinner, Button } from "react-bootstrap";

const SAVE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $name: String!, $thumbnailURL: String) {
    updateCategory(id: $id, name: $name, thumbnailURL: $thumbnailURL) {
      id
    }
  }
`;

function SaveCategoryView(props) {
  const [mutateFunction, { data, loading, error }] = useMutation(
    SAVE_CATEGORY,
    {
      refetchQueries: ["GetAllModels"],
    }
  );
  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    );
  } else {
    return (
      <Button
        style={{
          marginLeft: "4px",
          marginRight: "4px",
        }}
        variant="primary"
        onClick={(e) => {
          mutateFunction({
            variables: {
              id: props.category.id,
              name: props.category.name,
              thumbnailURL: props.category.thumbnailURL,
            },
          });
        }}
      >
        Save
      </Button>
    );
  }
}
export default SaveCategoryView;
