import { Button } from "react-bootstrap";
import { FaApple } from "react-icons/fa";
import NetworkService from "../../Services/NetworkService";
import "../SignUp&Login.css";

export default function AppleLogin() {
  const didLoginApple = (appleIdInfo) => {
    NetworkService.userMutations("")
      .loginWithApple(
        appleIdInfo.idToken,
        appleIdInfo.firstName,
        appleIdInfo.lastName
      )
      .then((response) => {
        console.log(response);
        if (window.inApp) {
          window.planetx.auth.login({
            token: response.data.loginWithApple.token,
            provider: "Apple",
          });
          window.planetx.view.dismiss();
        }
      })
      .catch((error) => {
        console.log(error.message);
        window.planetx.error(error.message);
      });
  };
  return (
    <Button
      variant=""
      className="externalLogin px-card"
      onClick={() => {
        window.planetx.auth.requestAppleLogin().then((appleIdInfo) => {
          console.log(appleIdInfo);
          didLoginApple(appleIdInfo);
        });
      }}
    >
      <FaApple size="30px" className="appleLogo" />
    </Button>
  );
}
