//Simple NavText without passing props
export default function NavText({ displayText, page }) {
  return (
    <span
      style={{
        textAlign: "center",
        fontSize: "small",
        color: "var(--c-accent)",
        fontWeight: "bold",
        display: "inline-block",
        marginLeft: 10,
        cursor: "pointer",
      }}
      onClick={() => {
        window.planetx.view.navigate({
          path: page.path,
          query: page.query,
          fullScreen: false,
        });
      }}
    >
      {displayText}
    </span>
  );
}
