import React from "react";
import "./SignUp&Login.css";
import { useLocation } from "react-router-dom";
import SignUpAndLoginViewModel from "../SignUp&Login/SignUpAndLoginViewModel";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginContainer({ children, page, refreshView }) {
  const allowSkip = useQuery().get("skip") === "allow";

  const viewModel = SignUpAndLoginViewModel({ allowSkip, refreshView, page });
  return (
    <div className={allowSkip ? "loginWrapper-transparent" : "loginWrapper"}>
      {children(viewModel)}
    </div>
  );
}
