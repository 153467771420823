import React, { useState } from "react";
import NetworkService from "../../../Services/NetworkService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { useCookies } from "react-cookie";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function PermissionsView() {
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasPremission, setHasPremission] = useState(false);
  const [error, setError] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["token", "email"]);
  if (isLoading) {
    NetworkService.permissions(cookies.token)
      .permissions()
      .then((result) => {
        console.log(result);
        try {
          var objectString = Buffer.from(
            result.data.permissions,
            "base64"
          ).toString();
          let obj = JSON.parse(objectString);
          setPermissions(obj);
          let a = {
            objectString,
            obj,
            o: obj,
          };
          console.log(a);
          if (Array.isArray(obj[cookies.email])) {
            let myPremissions = obj[cookies.email];
            console.log(myPremissions);
            setHasPremission(
              myPremissions.includes("*") ||
                myPremissions.includes("PERMISSION")
            );
          } else {
            setHasPremission(false);
          }
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  }

  const updateElement = (elementName, key, newArray) => {
    if (newArray.includes(elementName)) {
      newArray = newArray.filter(function (element) {
        return element != elementName && element != "*";
      });
    } else {
      newArray.push(elementName);
    }

    console.log(key, newArray);
    setIsLoading(true);
    NetworkService.permissions(cookies.token)
      .setPermissions(key, newArray)
      .then((result) => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <TextField
        id="standard-basic"
        label="Email"
        placeholder="example@email.com"
        value={newUserEmail}
        onChange={(e) => setNewUserEmail(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={() => {
          if (!validateEmail(newUserEmail)) {
            return;
          }
          if (Object.keys(permissions).includes(newUserEmail)) {
            return;
          }
          setIsLoading(true);
          NetworkService.permissions(cookies.token)
            .setPermissions(newUserEmail, [])
            .then((result) => {
              setIsLoading(false);
              setNewUserEmail("");
            });
        }}
      >
        Add User
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="permissions">
          <TableHead>
            <TableRow>
              <TableCell>User Permissions</TableCell>
              <TableCell align="right">All Permissions</TableCell>
              <TableCell align="right">Edit Permissions</TableCell>
              <TableCell align="right">Users</TableCell>
              <TableCell align="right">Models</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(permissions).map(([key, value], index) => (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>

                <TableCell align="right">
                  <Checkbox
                    checked={value.includes("*")}
                    disabled={!hasPremission}
                    onChange={() => {
                      updateElement("*", key, value);
                    }}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    disabled={
                      value.includes("*") ||
                      cookies.email === key ||
                      !hasPremission
                    }
                    checked={
                      value.includes("*") || value.includes("PERMISSION")
                    }
                    onChange={() => {
                      updateElement("PERMISSION", key, value);
                    }}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    disabled={value.includes("*") || !hasPremission}
                    checked={value.includes("*") || value.includes("USER")}
                    onChange={() => {
                      updateElement("USER", key, value);
                    }}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    disabled={value.includes("*") || !hasPremission}
                    checked={value.includes("*") || value.includes("MODEL")}
                    onChange={() => {
                      updateElement("MODEL", key, value);
                    }}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Snackbar
          open={error != ""}
          autoHideDuration={6000}
          onClose={() => {
            setError("");
          }}
        >
          <Alert
            onClose={() => {
              setError("");
            }}
            severity="success"
          >
            {error}
          </Alert>
        </Snackbar>
      </TableContainer>
    </Container>
  );
}
