import { BrowserRouter, Switch, Route } from "react-router-dom";

import AdminUI from "./AdminUI/AdminUIHomeView";
import Login from "../SignUp&Login/Login";
import Email from "../SignUp&Login/Email";
import UserId from "../SignUp&Login/UserId";
import FirstView from "../SignUp&Login/FirstView";
import LoginContainer from "../SignUp&Login/LoginContainer";

const FIRST_VIEW = 0;
const EMAIL_VIEW = 1;
const LOGIN_VIEW = 2;
const NICKNAME_VIEW = 3;
const USERID_VIEW = 4;

const refreshView = (page, allowSkip) => {
  window.planetx.react.componentDidMount();

  if (page == LOGIN_VIEW) {
    window.planetx.view.setNavigationTitle("Login");
  } else {
    window.planetx.view.setNavigationTitle("Sign up");
  }
  window.planetx.view.setShowNavigationBar(page != FIRST_VIEW);

  if (allowSkip) {
    window.planetx.view.setStatusIconColor("dark");
    window.planetx.view.setBackgroundColor("#00000000");
    window.planetx.view.setShowBlurBackground(true);
  }
};

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin">
          <AdminUI />
        </Route>
        <Route path="/app/email">
          <LoginContainer page={EMAIL_VIEW} refreshView={refreshView}>
            {(viewModel) => <Email viewModel={viewModel} />}
          </LoginContainer>
        </Route>
        <Route path="/app/firstview">
          <LoginContainer page={FIRST_VIEW} refreshView={refreshView}>
            {(viewModel) => <FirstView viewModel={viewModel} />}
          </LoginContainer>
        </Route>
        <Route path="/app/login">
          <LoginContainer page={LOGIN_VIEW} refreshView={refreshView}>
            {(viewModel) => <Login viewModel={viewModel} />}
          </LoginContainer>
        </Route>
        <Route path="/app/userid">
          <LoginContainer page={USERID_VIEW} refreshView={refreshView}>
            {(viewModel) => <UserId viewModel={viewModel} />}
          </LoginContainer>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
