import NetworkService from "../../../Services/NetworkService";
import { useCookies } from "react-cookie";

function PostListItem(props) {
  let postImage;
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  if (!props.post.author) {
    return <p />;
  }
  // console.log(props)
  if (props.post.image != null) {
    postImage = (
      <div>
        <img
          style={{ width: "500px", padding: "8px" }}
          src={props.post.image.downloadUrl}
        />
        <p>imageid: {props.post.image.id}</p>
      </div>
    );
  } else {
    postImage = <div />;
  }
  function handleClick(input) {
    NetworkService.postsMutations(cookies.token).deletePost(input.post.id);
    window.location.reload();
  }
  return (
    <div
      style={{
        borderStyle: "solid",
        borderWidth: "1px",
        margin: "8px",
        padding: "8px",
        borderRadius: "16px",
      }}
    >
      <h3>{props.post.content}</h3>
      <p>postid: {props.post.id}</p>
      <p>
        expiryDate:{" "}
        {new Date(props.post.meta.expiryDate * 1000).toLocaleString()}
      </p>
      <p>visibleGroup: {props.post.meta.visibleGroup}</p>

      <h4>{new Date(props.post.meta.created * 1000).toLocaleString()}</h4>
      <p>cloudAnchor: {props.post.cloudAnchor.cloudAnchorId}</p>
      <p>spatialAnchor: {props.post.cloudAnchor.spacialAnchorId}</p>
      
      <p>
        <img
          style={{ height: "50px", width: "50px", padding: "8px" }}
          src={props.post.author.profilePictureURL}
        />
        Author: <b>{props.post.author.nickName}</b> {props.post.author.email}
      </p>
      <button type="submit" onClick={() => handleClick(props)}>
        Delete Post
      </button>
      {postImage}
    </div>
  );
}

export default PostListItem;
