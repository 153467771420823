import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

var Permissions = (client) => {
  return {
    setPermissions: (email, permissions) => {
      console.log(email, permissions);
      return client.mutate({
        mutation: gql`
          mutation SetPermissions($email: String!, $permissions: [String!]!) {
            setPermissions(email: $email, permissions: $permissions)
          }
        `,
        variables: { email, permissions },
      });
    },
    permissions: () => {
      return client.query({
        query: gql`
          query Permissions {
            permissions
          }
        `,
      });
    },
  };
};

export default Permissions;
