import { ApolloClient, InMemoryCache, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

// const DELETE_POST = gql`
// mutation DeletePost($id: String!){
//     deletePost(id:$id) {
//         id
//     }
// }
// `
// const [deletePost, { data }] = useMutation(DELETE_POST);

// var PostsMutations = {
//   deletePost,
// }

var PostsMutations = (client) => {
  return {
    createPost: (inputPost) => {
      return client.mutation({
        query: gql`
          mutation ($inputPost: InputPost!) {
            createPost(inputPost: $inputPost) {
              id
            }
          }
        `,
        variables: {
          inputPost: inputPost,
        },
      });
    },
    deletePost: (id) => {
      return client.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deletePost(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });
    },
  };
};

export default PostsMutations;

// input InputPost {
//   address: InputAddress
//   content: String!
//   cloudAnchor: InputCloudAnchorNode!
//   # anchorInfo: InputAnchorInfoNode
//   location: InputLocationNode!
//   image: InputImage
//   modelId: ID
//   modelScale: Float
//   life: Float
//   isPublic: Boolean
//   hashtag: [String]
//   visibleGroup: String
//   isHorizontal: Boolean
//   mentionedUsers: [ID]
// }
