import NetworkService from "../../../Services/NetworkService";
import React, { useState } from "react";
import { useTable } from "react-table";
import { useCookies } from "react-cookie";
import {
  Container,
  Form,
  Col,
  Image,
  Button,
  Table,
  Alert,
  Spinner,
} from "react-bootstrap";
function InvitationCode() {
  const [currentUser, setCurrentUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [version, setVersion] = useState("1.0");
  const [allCodes, setAllCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(100);
  const [error, setError] = useState("");

  const uploadCSVFile = (e) => {
    console.log(e.target.files[0]);
  };

  if (loading) {
    NetworkService.invitationCodeQueries(cookies.token)
      .allCodes(version, skip, limit)
      .then((response) => {
        console.log(response.data.allCodes);
        setAllCodes(response.data.allCodes);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Registered Date",
        accessor: "registeredDate",
      },
      {
        Header: "Provided Date",
        accessor: "providedDate",
      },
      {
        Header: "Should Provide",
        accessor: "shouldProvide",
      },
      {
        Header: "Allow Register",
        accessor: "allowRegister",
      },
      {
        Header: "Referred User",
        accessor: "referredUser",
      },
      {
        Header: "Registered User",
        accessor: "registeredUser",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return allCodes.map((data) => {
      let flattenData = {
        code: data.code,
      };

      if (data.registeredDate) {
        flattenData.registeredDate = new Date(data.registeredDate * 1000);
      }

      if (data.providedDate) {
        flattenData.providedDate = new Date(1212 * 1000);
      }

      flattenData.shouldProvide = data.shouldProvide;
      flattenData.allowRegister = data.allowRegister;

      if (data.referredUser) {
        flattenData.referredUser = data.referredUser;
      }

      if (true) {
        flattenData.registeredUser = {
          email: "yinluke@gmail.com",
          nickName: "Luke Yin",
          profileUrl:
            "https://homepages.cae.wisc.edu/~ece533/images/airplane.png",
          render: (e) => {
            return (
              <div>
                <Col xs="auto" className="my-1">
                  <Image
                    style={{ height: "30px", width: "30px" }}
                    src={currentUser.profilePictureURL}
                    roundedCircle
                  />
                </Col>
                <Col xs="auto" className="my-1">
                  <Form.Label>
                    {currentUser.email + "(" + currentUser.nickName + ")"}
                  </Form.Label>
                </Col>
              </div>
            );
          },
        };
      }

      return flattenData;
    });
  });

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Container>
      {error ? <Alert variant="danger">{error}</Alert> : <div />}
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <Table size="sm" striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <br />
      <Alert variant="secondary">
        <Form.Row>
          <Form.Label>Page</Form.Label>
        </Form.Row>
        <Form.Row>
          <Col xs="auto">
            <Form.Control
              placeholder="Page #"
              value={skip / limit}
              onChange={(e) => setSkip(limit * parseInt(e.target.value))}
            />
          </Col>
          <Col xs="auto">
            <Form.Control
              placeholder="Items per page"
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Label>Version</Form.Label>
        </Form.Row>
        <Form.Row>
          <Col xs="auto">
            <Form.Control
              placeholder="Code Version"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <Button
              type="file"
              name="file"
              onClick={(e) => {
                setLoading(true);
              }}
            >
              Change Version
            </Button>
          </Col>
        </Form.Row>
      </Alert>
      <Alert variant="secondary">
        <Form.Group>
          <Form.Label>Add New Codes</Form.Label>
          <br />
          <Button type="file" name="file" onClick={uploadCSVFile}>
            Upload CSV File
          </Button>
        </Form.Group>
      </Alert>
    </Container>
  );
}

export default InvitationCode;
