import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

var PostsQueries = (client) => {
  return {
    getAllPosts: () => {
      return client.query({
        query: gql`
          query GetAllPosts {
            posts {
              id
              address {
                country
                city
              }
              content
              cloudAnchor {
                cloudAnchorId
                spacialAnchorId
              }
              author {
                email
                nickName
                profilePictureURL
              }
              meta {
                created
                expiryDate
                visibleGroup
              }
              image {
                id
                downloadUrl
              }
              location {
                latitude
                longitude
              }
            }
          }
        `,
      });
    },
  };
};

export default PostsQueries;
