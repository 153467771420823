import {
  BrowserRouter,
  useRouteMatch,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import PostsMainView from "./Posts/PostsMainView";
import ResolvingHostingMainView from "./ResolvingHosting/ResolvingHostingMainView";
import UsersMainView from "./Users/UsersMainView";
import ModelsLibrary from "./ModelsLibrary";
import React, { useState } from "react";
import LoginPage from "../LoginPage";
import CurrentUser from "../CurrentUser";
import { useCookies } from "react-cookie";
import InvitaionCode from "../AdminUI/InvitationCode";
import { Navbar, Nav, Form } from "react-bootstrap";
// import NewView from "../../NewView";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import env from "../../env";
import PermissionsView from "./PermissionsView";

function AdminUIHomeView() {
  let match = useRouteMatch();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const getClient = (token) => {
    var httpLink = new HttpLink({
      uri: `${env.api_http}://${env.api_host}:${env.api_port}/graphql`,
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          token: token,
        },
      }));

      return forward(operation);
    });

    return new ApolloClient({
      link: concat(authMiddleware, httpLink),
      cache: new InMemoryCache(),
    });
  };

  if (!cookies.token) {
    return <LoginPage />;
  }
  return (
    <ApolloProvider client={getClient(cookies.token)}>
      <BrowserRouter>
        <div>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">PlanetX Web</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href={`${match.url}/posts`}>Posts</Nav.Link>
                <Nav.Link href={`${match.url}/users`}>Users</Nav.Link>
                <Nav.Link href={`${match.url}/resolving_hosting`}>
                  Resolving Hosting
                </Nav.Link>
                <Nav.Link href={`${match.url}/invitation_code`}>
                  Invitation Code
                </Nav.Link>
                <Nav.Link href={`${match.url}/models_library`}>
                  Models Library
                </Nav.Link>
                <Nav.Link href={`${match.url}/permissions`}>
                  Permissions
                </Nav.Link>
                {/* <Nav.Link href={`${match.url}/newView`}>NewView</Nav.Link> */}
              </Nav>
              <Form inline>
                <CurrentUser />
              </Form>
            </Navbar.Collapse>
          </Navbar>
          <Switch>
            <Route path={`${match.url}/posts`}>
              <PostsMainView />
            </Route>
            <Route path={`${match.url}/newView`}>{/* <NewView /> */}</Route>
            <Route path={`${match.url}/users`}>
              <UsersMainView />
            </Route>
            <Route path={`${match.url}/resolving_hosting`}>
              <ResolvingHostingMainView />
            </Route>
            <Route path={`${match.url}/test_link`}>
              <h1> Test Link </h1>
            </Route>
            <Route path={`${match.url}/invitation_code`}>
              <InvitaionCode />
            </Route>
            <Route path={`${match.url}/models_library`}>
              <ModelsLibrary />
            </Route>
            <Route path={`${match.url}/permissions`}>
              <PermissionsView />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default AdminUIHomeView;
