import { gql, useMutation } from "@apollo/client";
import { Spinner, Button } from "react-bootstrap";

const CREATE_MODEL = gql`
  mutation CretaeModel($model: InputModel!) {
    createModel(model: $model) {
      id
      author
      name
      modelDescription
      usdzFileURL
      modelFileURL
      uploader {
        email
        lastName
        firstName
      }
      updated
      thumbnailURL
      avaliable
      category {
        name
      }
    }
  }
`;

function CreateModelView(props) {
  const [mutateFunction, { data, loading, error }] = useMutation(CREATE_MODEL, {
    refetchQueries: ["GetAllModels"],
  });
  if (error) {
    window.alert(error);
  }
  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    );
  } else {
    return (
      <Button
        style={{
          marginLeft: "4px",
          marginRight: "4px",
        }}
        variant="primary"
        onClick={(e) => {
          mutateFunction({ variables: { model: props.model } })
            .then((res) => {
              let createdModel = res.data.createModel;
              createdModel.type = "model";
              props.dismiss(createdModel);
            })
            .catch((error) => {
              window.alert(error);
            });
        }}
      >
        Create
      </Button>
    );
  }
}
export default CreateModelView;
