import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

var UserMutations = (client) => {
  return {
    createUser: (email, password, nickName) => {
      return client.mutate({
        mutation: gql`
          mutation {
            createUser(nickName:"${nickName}", email:"${email}", password: "${password}", firstName: "${nickName}") {
              token
            }
          }
        `,
      });
    },
    loginWithGoogle: (idToken, clientName) => {
      return client.mutate({
        mutation: gql`
              mutation Login {
                  loginWithGoogle(idToken: "${idToken}", client: "${clientName}") {
                    token
                }
            }
          `,
      });
    },
    loginWithApple: (idToken, firstName, lastName) => {
      return client.mutate({
        mutation: gql`
              mutation Login {
                loginWithApple(idToken: "${idToken}", firstName: "${firstName}", lastName: "${lastName}") {
                    token
                    email
                }
            }
          `,
      });
    },
  };
};

export default UserMutations;
