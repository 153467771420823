import { ApolloClient, InMemoryCache, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import env from "../../env";

var InvitationCodeQueries = (client) => {
  return {
    allCodes: (version, skip, limit) => {
      return client.query({
        query: gql`
          query GetAllCode {
            allCodes(version: "${version}", skip: ${skip}, limit: ${limit}) {
                code
                registeredUser { 
                  id
                  email
                  nickName
                  firstName
                  lastName
                  profilePictureURL
                }
                referredUser {
                  id
                  email
                  nickName
                  firstName
                  lastName
                  profilePictureURL
                }
                registeredDate
                providedDate
                shouldProvide
                allowRegister
            }
          }
        `,
      });
    },
  };
};

export default InvitationCodeQueries;
