import React, { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Spinner, Button, Image, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import CreateModelView from "./CreateModelView";
import DeleteCategoryView from "./DeleteCategoryView";
import DeleteModelView from "./DeleteModelView";
import SaveCategoryView from "./SaveCategoryView";
import SaveModelView from "./SaveModelView";
import { gql, useMutation } from "@apollo/client";
import path from "path";
import env from "../../../env";

var viewType = null;

const CREATE_POST_LOCATION = gql`
  mutation CreatePost($inputPost: InputPost!) {
    createPost(inputPost: $inputPost) {
      id
    }
  }
`;

function fileName(url) {
  let components = url.split("/");
  if (components.length > 1) {
    return components[components.length - 1];
  } else {
    return components[0];
  }
}

function ModelsLibraryDetailView(props) {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [modelName, setModelName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [description, setDescription] = useState("");
  const [modelCategory, setModelCategory] = useState("");
  const [isAvaliable, setAvaliable] = useState(true);
  const [isUploading, setUploading] = useState(false);
  const [modelFileURL, setModelFileURL] = useState("");
  const [usdzFileURL, setUSDZFileURL] = useState("");

  //Location Input
  const [inputLatitude, setInputLatitude] = useState("");
  const [inputLongitude, setInputLongitude] = useState("");
  const [inputAltitude, setInputAltitude] = useState("");
  const [inputContent, setInputContent] = useState("");

  const [viewId, setId] = useState("");
  const uploadUrl = `${env.cdn_http}://${env.cdn_host}:${env.cdn_port}/uploadfileonly`;
  const [mutateFunction, { data, createPostLoading, error }] = useMutation(
    CREATE_POST_LOCATION,
    {
      refetchQueries: ["CreatePost"],
    }
  );

  const onDrop = useCallback((acceptedFiles) => {
    console.log(viewType);
    if (viewType != "category" && viewType != "model") {
      return;
    }
    let file = acceptedFiles[0];
    let pathComponents = file.name.split(".");
    let extension = "";
    let fileName = "file";
    if (pathComponents.length > 1) {
      extension = pathComponents[pathComponents.length - 1];
      fileName = pathComponents[0];
    } else if (pathComponents.length == 1) {
      fileName = pathComponents[0];
    }
    var formData = new FormData();

    let type = "";
    if (file.type.split("/")[0] === "image") {
      type = "image";
    } else if (file.type.split("/")[0] === "model") {
      if (extension == "usdz" || extension == "usdc" || extension == "usd") {
        type = "usdz";
      } else {
        type = "models";
      }
    } else {
      type = "models";
    }

    console.log(type, fileName);

    if (viewType == "categiory" && type != "image") {
      return;
    }

    let id = path.basename(file.name);
    formData.append("file", file);
    formData.append("id", id);
    formData.append("type", type);

    // console.log(file.name)
    setUploading(true);
    return fetch(`${uploadUrl}/${type}/${id}/`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.text().then((url) => {
          setUploading(false);
          if (type == "usdz") {
            setUSDZFileURL(url);
          }
          if (type == "models") {
            setModelFileURL(url);
          }
          if (type == "image") {
            setThumbnailURL(url);
          }
        });
      })

      .catch((error) => {
        console.log(error);
        setUploading(false);
        window.alert("Error when upload the file");
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!props.focused) {
    if (viewType != null || viewId != null) {
      viewType = null;
      setId(null);
    }
    return (
      <Row>
        <p>No models selected</p>
      </Row>
    );
  }
  if (props.focused.type == "category") {
    let category = props.focused;
    viewType = "category";
    if (viewId != category.id) {
      setCategoryName(category.name);
      setThumbnailURL(category.thumbnailURL);
      setId(category.id);
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <h4>{category.name}</h4>
          <div style={{ flex: 1 }} />
          <DeleteCategoryView categoryId={category.id} />
          <SaveCategoryView
            category={{
              name: categoryName,
              id: viewId,
              thumbnailURL: thumbnailURL,
            }}
          />
        </div>
        <Form>
          <Form.Label>
            {"Last Updated: " + Date(category.updated * 1000).toString()}
          </Form.Label>

          {isUploading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden" />
            </Spinner>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <button></button>
                <div
                  style={{
                    height: "200px",
                    width: "250px",
                    backgroundColor: "#eeeeee",
                    boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.3)",
                  }}
                  {...getRootProps()}
                >
                  <img
                    style={{
                      height: "200px",
                      width: "250px",
                      objectFit: "contain",
                    }}
                    src={thumbnailURL}
                  />
                </div>
              </div>
            </div>
          )}

          <Form.Group controlId="formCategoryName">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Untitled Category"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
  if (props.focused.type == "model") {
    viewType = "model";
    let uploader = null;
    let model = {};
    let isNewModel = false;
    if (props.focused.isNew) {
      isNewModel = true;
    } else {
      model = props.focused;
      if (viewId != model.id) {
        setModelName(model.name);
        setAuthorName(model.author);
        setDescription(model.modelDescription);
        setUSDZFileURL(model.usdzFileURL);
        setModelFileURL(model.modelFileURL);
        setThumbnailURL(model.thumbnailURL);
        setAvaliable(model.avaliable);
        setModelCategory(model.category.name);
        setId(model.id);
      }
      uploader = model.uploader;
    }
    console.log(viewType);
    console.log(viewId);
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <h4>{model.name}</h4>
          <h5>{model.id}</h5>
          <div style={{ flex: 1 }} />
          {isNewModel ? null : <DeleteModelView modelId={model.id} />}
          {isNewModel ? (
            <CreateModelView
              model={{
                id: viewId,
                name: modelName,
                author: authorName,
                modelDescription: description,
                usdzFileURL: usdzFileURL,
                modelFileURL: modelFileURL,
                thumbnailURL: thumbnailURL,
                avaliable: isAvaliable,
                categoryName: modelCategory,
              }}
              dismiss={(created) => {
                props.dismiss(created);
              }}
            />
          ) : (
            <SaveModelView
              model={{
                id: viewId,
                name: modelName,
                author: authorName,
                modelDescription: description,
                usdzFileURL: usdzFileURL,
                modelFileURL: modelFileURL,
                thumbnailURL: thumbnailURL,
                avaliable: isAvaliable,
                categoryName: modelCategory,
              }}
            />
          )}
        </div>

        <Form>
          <Form.Label>
            {"Last Updated: " + Date(model.updated * 1000).toString()}
          </Form.Label>
          {uploader ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Image
                style={{ height: "30px", width: "30px", margin: "10px" }}
                src={uploader.profilePictureURL}
                roundedCircle
              />

              <Form.Label>
                {"Uploader: " + uploader.email + "(" + uploader.nickName + ")"}
              </Form.Label>
            </div>
          ) : null}
          {isUploading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden" />
            </Spinner>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Form.Label>Model Thumbnail</Form.Label>
                <div
                  style={{
                    height: "200px",
                    width: "250px",
                    backgroundColor: "#eeeeee",
                    boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.3)",
                  }}
                  {...getRootProps()}
                >
                  <img
                    style={{
                      height: "200px",
                      width: "250px",
                      objectFit: "contain",
                    }}
                    src={thumbnailURL}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Form.Label>Model File</Form.Label>
                <div
                  style={{
                    height: "200px",
                    width: "250px",
                    padding: "8px",
                    backgroundColor: "#eeeeee",
                    boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.3)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  {...getRootProps()}
                >
                  {modelFileURL && modelFileURL.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InsertDriveFileIcon />
                      <Typography variant="caption" color="inherit">
                        {fileName(modelFileURL)}
                      </Typography>
                      <Button size="sm" href={modelFileURL}>
                        Download
                      </Button>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={(e) => {
                          setModelFileURL("");
                        }}
                      >
                        RemoveFile
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Form.Label>Model USDZ File</Form.Label>
                <div
                  style={{
                    height: "200px",
                    width: "250px",
                    padding: "8px",
                    backgroundColor: "#eeeeee",
                    boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.3)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  {...getRootProps()}
                >
                  {usdzFileURL && usdzFileURL.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InsertDriveFileIcon />
                      <Typography variant="caption" color="inherit">
                        {fileName(usdzFileURL)}
                      </Typography>

                      <Button size="sm" href={usdzFileURL}>
                        Download
                      </Button>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={(e) => {
                          setUSDZFileURL("");
                        }}
                      >
                        RemoveFile
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          <Form.Group controlId="formModelName">
            <Form.Label>Model Name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Untitled Model"
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formModelAuthor">
            <Form.Label>Model Author</Form.Label>
            <Form.Control
              type="name"
              placeholder="Model Author"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formModelCategory">
            <Form.Label>Model Category</Form.Label>
            <Form.Control
              type="name"
              placeholder="Default Category"
              value={modelCategory}
              onChange={(e) => setModelCategory(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formModelAvaliable">
            <Button
              variant={isAvaliable ? "outline-success" : "outline-secondary"}
              onClick={(e) => {
                setAvaliable(!isAvaliable);
              }}
            >
              {isAvaliable ? "Enabled" : "Disabled (Wont show in app)"}
            </Button>
          </Form.Group>
          <Form.Group controlId="formModelDescription">
            <Form.Label>Model Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Model Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formModelSubmitLocation">
            {createPostLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden" />
              </Spinner>
            ) : (
              <div>
                <h1>Input Post Location</h1>

                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Location"
                    onChange={(e) => {
                      let inputs = e.target.value.split(",");
                      if (inputs.length == 2) {
                        setInputLongitude(inputs[1].trim());
                        setInputLatitude(inputs[0].trim());
                      } else {
                        setInputLongitude("0");
                        setInputLatitude("0");
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Latitude</Form.Label>

                  <Form.Control
                    type="name"
                    placeholder="Location"
                    value={inputLatitude}
                    onChange={(e) => setInputLatitude(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Longitude"
                    value={inputLongitude}
                    onChange={(e) => setInputLongitude(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Altitude</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Altitude"
                    value={inputAltitude}
                    onChange={(e) => setInputAltitude(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Content</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Content"
                    value={inputContent}
                    onChange={(e) => setInputContent(e.target.value)}
                  />
                </Form.Group>
                <Button
                  onClick={(e) => {
                    mutateFunction({
                      variables: {
                        inputPost: {
                          address: {
                            country: "Canada",
                            city: "Toronto",
                          },
                          content: inputContent,
                          cloudAnchor: {},
                          location: {
                            latitude: parseFloat(inputLatitude),
                            longitude: parseFloat(inputLongitude),
                            altitude: parseFloat(inputAltitude),
                          },
                          modelId: model.id,
                          modelScale: 1.0,
                          isPublic: true,
                          hashtag: [],
                          mentionedUsers: [],
                        },
                      },
                    });
                  }}
                >
                  Post Location
                </Button>
              </div>
            )}
          </Form.Group>
        </Form>
      </div>
    );
  }

  if (viewType != null || viewId != null) {
    viewType = null;
    setId(null);
  }

  return (
    <Row>
      <p>No models selected</p>
    </Row>
  );
}
export default ModelsLibraryDetailView;
