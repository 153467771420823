import React, { useState, useEffect } from "react";
import { Form, Card, Alert } from "react-bootstrap";

import NavText from "./components/NavText";
import "./SignUp&Login.css";

export default function Email({ viewModel }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  window.delegate = {
    pageDidAppear: () => {
      viewModel.refreshView();
    },
    pageShouldRefresh: () => {
      setShouldUpdate(!shouldUpdate);
    },
  };

  useEffect(() => {
    viewModel.refreshView();
  }, []);

  useEffect(() => {
    setError("");
  }, [email]);

  return (
    <div>
      <div className="flexCloumn">
      <h1
          style={{
            marginTop: 50,
            marginBottom: 150,
            fontSize: "x-large",
            fontWeight: "800",
            color: "var(--c-primary-label)",
          }}
        >
          Sign up with your email
        </h1>

        {/* <span className="primarySubTitle">You can sign up with your email</span> */}

        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              className={
                error
                  ? "inputFormError inputSizePrimary"
                  : "inputForm inputSizePrimary"
              }
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Set Your Password"
              value={password}
              className={
                error
                  ? "inputFormError inputSizePrimary"
                  : "inputForm inputSizePrimary"
              }
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          {error ? (
            <Alert variant="" className="errorAltert">
              {error}{" "}
              {showLogin ? (
                <NavText
                  displayText={"LOG IN"}
                  page={{
                    path: "app/login",
                    query: `skip=${viewModel.allowSkip ? "allow" : "disallow"}`,
                  }}
                />
              ) : (
                ""
              )}
            </Alert>
          ) : (
            <div style={{ height: 65 }}>
              <br />
            </div>
          )}
          <div>
            <button
              type="button"
              className="primaryButton inputSizePrimary"
              onClick={() => {
                viewModel
                  .emailCorrectToProceed({
                    email,
                    password,
                    setError,
                    setShowLogin,
                    setShowLogin,
                  })
                  .then();
              }}
            >
              Next
            </button>

            <br />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 55,
            }}
          ></div>
        </Form>
      </div>
    </div>
  );
}
