import UsersQueries from "./GraphQL/UsersQueries";
import UserMutations from "./GraphQL/UserMutations";
import ModelsQueries from "./GraphQL/ModelsQueries";
import ModelsMutations from "./GraphQL/ModelsMutations";
import PostsQueries from "./GraphQL/PostsQueries";
import SessionQueries from "./GraphQL/SessionQueries";
import PostsMutations from "./GraphQL/PostsMutations";
import InvitationCodeQueries from "./GraphQL/InvitationCodeQueries";
import Permissions from "./GraphQL/Permissions";
import { useCookies } from "react-cookie";
import env from "../env";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";

const getClient = (token) => {
  var httpLink = new HttpLink({
    uri: `${env.api_http}://${env.api_host}:${env.api_port}/graphql`,
    /// uri: "http://192.168.1.66:5748/graphql",
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        token: token,
      },
    }));

    return forward(operation);
  });

  return new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
  });
};

var NetworkService = {
  permissions: (token) => Permissions(getClient(token)),
  usersQueries: (token) => UsersQueries(getClient(token)),
  userMutations: (token) => UserMutations(getClient(token)),
  modelsQueries: (token) => ModelsQueries(getClient(token)),
  modelsMutations: (token) => ModelsMutations(getClient(token)),
  postsQueries: (token) => PostsQueries(getClient(token)),
  postsMutations: (token) => PostsMutations(getClient(token)),
  sessionQueries: (token) => SessionQueries(getClient(token)),
  invitationCodeQueries: (token) => InvitationCodeQueries(getClient(token)),
};

export default NetworkService;
