import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import { formatMs } from "@material-ui/core";
import env from "../../env";

var UsersQueries = (client) => {
  return {
    login: (email, password) => {
      return client.query({
        query: gql`
          query Login {
            login(email: "${email}", password: "${password}") {
                token
            }
        }
      `,
      });
    },
    getAllUsers: () => {
      return client.query({
        query: gql`
          query GetAllUserss {
            users {
              id
              email
              nickName
              firstName
              lastName
              profilePictureURL
            }
          }
        `,
      });
    },
    currentUser: () => {
      return client.query({
        query: gql`
          query CurrentUser {
            currentUser {
              id
              email
              nickName
              firstName
              lastName
              profilePictureURL
            }
          }
        `,
      });
    },
    emailExist: (email) => {
      const content = {
        query: gql`
        query EmailExist {
          emailExist(email: "${email}")
        }
      `,
      };
      const result = client.query(content);

      return result;
    },
    checkNickNameUnique: (nickName) => {
      return client.query({
        query: gql`
          query CheckNickNameUnique {
              checkNickNameUnique(nickName: "${nickName}")
          }  
        `,
      });
    },
    // capture username in the form, but needs to seperate into first name and last name?
  };
};

export default UsersQueries;
