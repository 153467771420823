import { gql, useMutation } from "@apollo/client";
import { Spinner, Button } from "react-bootstrap";

const DELETE_MODEL = gql`
  mutation RemoveModel($id: ID!) {
    removeModel(id: $id)
  }
`;

function DeleteModelView(props) {
  const [mutateFunction, { data, loading, error }] = useMutation(DELETE_MODEL, {
    refetchQueries: ["GetAllModels"],
  });
  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    );
  } else {
    return (
      <Button
        style={{
          marginLeft: "4px",
          marginRight: "4px",
        }}
        variant="danger"
        onClick={(e) => {
          mutateFunction({ variables: { id: props.modelId } });
        }}
      >
        Delete
      </Button>
    );
  }
}
export default DeleteModelView;
