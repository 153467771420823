function UserListItem(props) {
  console.log(props);
  return (
    <div>
      <h3>{props.user.nickName}</h3>
      <h4>{props.user.email}</h4>
      <p>
        Author: <img style={{height: "50px", width: "50px", padding: "8px"}} src={props.user.profilePictureURL} />
      </p>
      <p>{props.user.id}</p>
    </div>
  );
}

export default UserListItem;
