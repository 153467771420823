import { gql, useMutation } from "@apollo/client";
import { Spinner, Button } from "react-bootstrap";

const DELETE_CATEGORY = gql`
  mutation RemoveCategory($id: ID!) {
    removeCategory(id: $id)
  }
`;

function DeleteCategoryView(props) {
  const [mutateFunction, { data, loading, error }] = useMutation(
    DELETE_CATEGORY,
    {
      refetchQueries: ["GetAllModels"],
    }
  );
  if (error) {
    window.alert(error);
  }
  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    );
  } else {
    return (
      <Button
        style={{
          marginLeft: "4px",
          marginRight: "4px",
        }}
        variant="danger"
        onClick={(e) => {
          mutateFunction({ variables: { id: props.categoryId } });
        }}
      >
        Delete
      </Button>
    );
  }
}
export default DeleteCategoryView;
