import React, { useState } from "react";
import NetworkService from "../../../Services/NetworkService";
import PostListItem from "./PostListItem";
import { useCookies } from "react-cookie";

function PostsMainView() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  if (isLoading) {
    NetworkService.postsQueries(cookies.token)
      .getAllPosts()
      .then((result) => {
        setPosts(result.data.posts);
        setIsLoading(false);
      });
  }

  const postsList = posts.map((post) => (
    <li key={post.id}>
      <PostListItem post={post} />
    </li>
  ));

  return (
    <div>
      <ul>{postsList}</ul>
    </div>
  );
}

export default PostsMainView;
